<template>
  <div id="ClassManagement">
    <v-row>
      <v-col cols="12" sm="3">
        <div class="title">
          <h1 class>{{ $t("Class Management") }}</h1>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="icons text-center">
          <span>
            <label class="search" for="inpt_search">
              <input
                id="inpt_search"
                @focus="inputFocus"
                @blur="inputBlur"
                type="text"
                v-model="search"
              />
            </label>
          </span>

          <span title="Filter">
            <v-menu
              v-model="filterMenu"
              offset-y
              :close-on-content-click="false"
              :nudge-width="120"
              offset-x
            >
              <template v-slot:activator="{ on }">
                <i
                  :title="$t('Filter')"
                  v-on="on"
                  class="fas fa-filter main-color fa-lg"
                ></i>
              </template>

              <v-list style="margin-top: 25px !important">
                <v-list-item class="select">
                  <v-select
                    v-model="selectedGrades"
                    :items="relatedGrades"
                    item-text="grade_name"
                    item-value="id"
                    :label="$i18n.t('Select Grade')"
                    multiple
                    solo
                  ></v-select>
                </v-list-item>
                <v-list-item v-if="admin" class="select">
                  <v-select
                    v-model="selectedTeachers"
                    :items="teachers"
                    item-text="name"
                    item-value="id"
                    :label="$t('Select Teacher')"
                    multiple
                    solo
                  ></v-select>
                </v-list-item>

                <!-- <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="primary" text @click="filterMenu = false"
                    >close</v-btn
                  >
                </v-card-actions>-->
              </v-list>
            </v-menu>
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="select">
        <v-row v-if="!teacher">
          <v-col cols="6" sm="6" class="academic">
            <label for="academicPeriods" class="label">{{
              $t("Academic Year")
            }}</label>
          </v-col>
          <v-col cols="6" sm="6">
            <v-select
              id="academicPeriods"
              v-model="selectedAcademicPeriodId"
              :items="academicPeriods"
              item-text="en.name"
              item-value="id"
              dense
              outlined
              @change="onSelect"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn
          icon
          to="/systemlogging/class_management"
          v-if="activitylogPermission"
          target="_blank"
        >
          <v-icon class="main-color" large :title="$t('System Log')"
            >mdi-archive-clock</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(item, i) in classes"
        :key="i"
        ><!-- <strong>{{ item.academic_grade }}</strong> 
        <div class="head-class">
          <span>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span dark v-on="on" v-if="item.name">
                  {{ getTitleSlice(item.academic_grade, item.name) }}
                  <span
                    v-if="getTitle(item.academic_grade, item.name).length > 20"
                    >..</span
                  >
                </span>
              </template>
              <span>{{ getTitle(item.academic_grade, item.name) }}</span>
            </v-tooltip>
          </span>
        </div>
        -->
        <v-card
          class="mx-auto classCard"
          :style="{
            borderColor: item.grade_color ? item.grade_color : '#7297ff',
          }"
        >
          <v-card-text>
            <h2>{{ getGradeTitle(item.academic_grade) }}</h2>

            <v-row>
              <v-col class="itemsCols" cols="12" sm="2">
                <v-icon class="mb-2" v-if="item.enable_online_meeting">
                  language</v-icon
                >
              </v-col>
              <v-col class="itemsCols" cols="12" sm="8">
                <p>{{ getTitleClass(item.name) }}</p>
              </v-col>
              <v-col
                class="itemsCols"
                v-if="
                  (elearningClassVisible && isTeacher == null) ||
                  (elearningClassVisible &&
                    isTeacher != null &&
                    isTeacher == item.teacher_id)
                "
                cols="12"
                sm="2"
              >
                <v-switch
                  v-if="admin"
                  v-model="item.enable_online_meeting"
                  @change="
                    changOnlineMeetingStatus(
                      item.id,
                      item.enable_online_meeting
                    )
                  "
                ></v-switch>
              </v-col>
            </v-row>
            <v-row class="classBox-text">
              <v-col class="itemsCols" cols="12" sm="2">
                <v-icon class="mb-2">people</v-icon>
              </v-col>
              <v-col class="itemsCols" cols="12" sm="10">
                {{ $t("Current Capacity") }} :
                <span
                  v-if="item.students_count > item.current_capacity"
                  style="color: #ffd267"
                >
                  {{ item.current_capacity }} / {{ item.students_count }}
                </span>
                <span v-else-if="item.students_count === item.current_capacity">
                  {{ item.current_capacity }} / {{ item.students_count }}
                </span>
                <span v-else style="color: #cc0000">
                  {{ item.current_capacity }} / {{ item.students_count }}
                </span>
              </v-col>
            </v-row>

            <v-row class="classBox-text">
              <v-col class="itemsCols" cols="12" sm="2">
                <v-icon class="mb-2">person</v-icon>
              </v-col>
              <v-col class="itemsCols" cols="12" sm="10">
                {{ $t("Head Teacher") }} :<span>{{ item.teacher }}</span>
              </v-col>
            </v-row>

            <div class="btn-div">
              <v-btn
                :style="{
                  backgroundColor: item.grade_color
                    ? item.grade_color
                    : '#7297ff',
                }"
                class="manageClass-btn"
                @click="goToManageClass(item.id, item)"
                >{{ $t("Manage Class") }}</v-btn
              >
            </div>

            <!--      <v-row>
              <v-col
                cols="12"
                md="12"
                lg="5"
                class="btn-div"
                v-if="listStudentsVisible || listExamsVisible"
              >
                <v-btn
                  large
                  class="manageClass-btn"
                  @click="goToManageClass(item.id)"
                  >{{ $t("Manage Class") }}</v-btn
                >
              </v-col>
              <v-col cols="12" md="12" lg="7">
                <table class="class-table">
                  <tr>
                    <td class="key">{{ $t("Maximum Capacity") }}</td>
                    <td>{{ item.students_count }}</td>
                  </tr>
                  <tr>
                    <td class="key">{{ $t("Current Capacity") }}</td>
                    <td>{{ item.current_capacity }}</td>
                  </tr>
                  <tr>
                    <td class="key">{{ $t("Empty Seats") }}</td>
                    <td>{{ item.empty_seats }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="5">
                <p>
                  <span class="icon main-color">
                    <i class="fas fa-chalkboard-teacher"></i>
                  </span>
                  {{ $t("Head Teacher") }}
                </p>
              </v-col>
              <v-col cols="6" sm="7">
                <p>{{ item.teacher }}</p>
              </v-col>
            </v-row>

             Elearning Success Btn switch
            <v-row
              v-if="
                (elearningClassVisible && isTeacher == null) ||
                (elearningClassVisible &&
                  isTeacher != null &&
                  isTeacher == item.teacher_id)
              "
            >
              <v-col cols="6" sm="6">
                 <v-icon large class="main-color add-icon" >mdi-buddhism </v-icon> 
                <img src="../../assets/elearning.svg" width="25" />
                <strong class="main-color">{{ $t("E-Learning") }}</strong>
              </v-col>
              <v-col cols="6" sm="6">
                <v-switch
                  v-model="item.enable_online_meeting"
                  @change="
                    changOnlineMeetingStatus(
                      item.id,
                      item.enable_online_meeting
                    )
                  "
                ></v-switch>
              </v-col>
            </v-row> -->
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-alert
          class="info_alert text-center"
          v-if="classes.length == 0 && loaded == true"
          >{{ $t("No classes available") }}</v-alert
        >
      </v-col>
    </v-row>

    <div class="loader text-center" v-if="!loaded">
      <img src="../../assets/Spinner-1s-200px.gif" alt />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";

export default {
  name: "ClassManagement",
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),
      teacher: ACL.Role("Teacher"),
      filterMenu: false,
      admin: ACL.Role("Super-Admin"),
      academicPeriods: [],
      selectedAcademicPeriodId: "",
      activeAcademicPeriod: "",
      academicId: "",
      search: "",
      relatedGrades: [],
      selectedGrades: [],
      selectedTeachers: [],
      gradeColor: "",
      classes: [],
      teachers: [],
      loaded: true,
      isTeacher: "",
      listStudentsVisible: ACL.checkPermission("list-student-assign-to-class"),
      elearningClassVisible: ACL.checkPermission("activate-class-elearning"),
      listExamsVisible: ACL.checkPermission("list-exams"),
    };
  },
  watch: {
    $route() {
      let academicId = this.$router.currentRoute.params.academicId;
      if (academicId != "all") {
        this.selectedAcademicPeriodId = Number(academicId);
      } else {
        this.selectedAcademicPeriodId = this.activeAcademicPeriod;
      }
      this.getRelatedGrades();
      this.getClassesObj();
    },
    selectedAcademicPeriodId: {
      handler() {
        if (this.selectedGrades.length > 0) {
          // console.log("dsfakjlfaaaaacccccaaaaas");
          this.selectedGrades = [];
        }
        if (this.classes.length > 0) {
          this.classes = [];
        }
      },
    },
    search: {
      handler() {
        this.getClassesObj();
      },
    },
    selectedGrades: {
      handler() {
        // console.log("dsfakjlfaaaaaaaaaas");
        this.getClassesObj();
      },
    },
    selectedTeachers: {
      handler() {
        // console.log("dsfakjlfaaaaaaaaaas");
        this.getClassesObj();
      },
    },
  },
  methods: {
    getTitleClass(className) {
      return className;
    },
    getGradeTitle(gradeName) {
      return gradeName;
    },
    getTitleSlice(gradeName, className) {
      let str = gradeName + " " + className;
      return str.slice(0, 20);
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
      }
    },
    getTeachers() {
      axios
        .get(this.getApiUrl + "/homework/getTeachers", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.teachers = response.data.data;
        });
    },
    getAcademicPeriods() {
      axios
        .get(this.getApiUrl + "/school/showAcademicPeriod?list=yes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.academicPeriods = response.data.data;
          if (this.selectedAcademicPeriodId == "all") {
            this.selectedAcademicPeriodId = this.academicPeriods.find(
              (object) => object.is_active == 1
            ).id;

            this.activeAcademicPeriod = this.selectedAcademicPeriodId;
            this.getRelatedGrades();
            this.getClassesObj();
          }
        });
    },
    getRelatedGrades() {
      axios
        .get(
          this.getApiUrl +
            "/school/getAcademicPeriodGrade/" +
            this.activeAcademicPeriod,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.relatedGrades = response.data.data;
        });
    },

    getusertecherid() {
      axios
        .get(this.getApiUrl + "/teachers/getusertecherid", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.isTeacher = response.data.data;
        });
    },
    getClassesObj() {
      this.loaded = false;
      let filter = {
        name: this.search,
        academic_grade_id: this.selectedGrades,
        teachers: this.selectedTeachers,
      };
      axios
        .get(
          this.getApiUrl +
            "/classes/nested/" +
            this.selectedAcademicPeriodId +
            "?all_data",
          {
            params: {
              filter: filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.classes = response.data.data;
          this.loaded = true;
        });
    },
    goToManageClass(classId, item) {
      this.$router.push({
        path:
          `/manageclass/` +
          this.selectedAcademicPeriodId +
          `/` +
          classId +
          "?tab=0&page=1",
      });
    },
    onSelect() {
      if (this.selectedAcademicPeriodId) {
        this.$router
          .push({
            path: `/classmanagement/` + this.selectedAcademicPeriodId,
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {});
      }
    },
    changOnlineMeetingStatus(classId, enabelMeeting) {
      if (enabelMeeting == true) {
        enabelMeeting = 1;
      } else {
        enabelMeeting = 0;
      }
      axios
        .get(
          this.getApiUrl +
            "/classes/enableOnlineMeeting/" +
            classId +
            "/" +
            enabelMeeting,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.getClassesObj();
        });
    },
  },
  mounted() {
    let academicId = this.$router.currentRoute.params.academicId;
    this.selectedAcademicPeriodId = academicId;

    if (academicId != "all") {
      this.selectedAcademicPeriodId = Number(academicId);
    }
    // console.log(this.selectedAcademicPeriodId);
    this.getAcademicPeriods();
    // console.log("id:" + this.selectedAcademicPeriodId);
    // this.getRelatedGrades();
    this.getClassesObj();
    this.getusertecherid();
    this.getTeachers();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";

icons {
  span {
    font-size: 20px;
  }
}

.head-class {
  width: 75%;
  padding: 0.6rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  background-color: $main-color;
  color: #ffffff;
  height: 50px;
}
.itemsCols {
  padding: 0px;
}
.classCard {
  padding: 10px 0;
  border-top: 12px solid;
  border-radius: 10px;
  padding: 1rem;
  .classBox-text {
    font-size: 10px;
    font-weight: bold;
    span {
      font-weight: 400;
    }
  }

  .btn-div {
    display: flex;
    justify-content: center;
    .manageClass-btn {
      /*  background-color: #7297ff !important; */
      color: #ffffff !important;
      text-transform: none;
      margin-top: 0.5rem;
      border-radius: 50px;
      box-shadow: 0px 2px 5px 1px #bcbcbc;
    }
  }

  .class-table {
    width: 100%;

    .key {
      color: $main-color;
    }
  }
}

.info_alert {
  margin-top: 17rem;
  text-align: center;
}

.loader img {
  margin-top: 50px;
  display: inline-block;
  margin-right: 2rem;
}

.classCard {
  p {
    margin-bottom: 0 !important;
  }
}
.title_font {
  font-size: 22px !important;
}
.select {
  margin-top: -10px;
}

.academic {
  text-align: right;
}

@media (max-width: 600px) {
  .academic {
    text-align: left;
  }
}
</style>
