import { render, staticRenderFns } from "./ClassManagement.vue?vue&type=template&id=623c86c9&scoped=true&"
import script from "./ClassManagement.vue?vue&type=script&lang=js&"
export * from "./ClassManagement.vue?vue&type=script&lang=js&"
import style0 from "./ClassManagement.vue?vue&type=style&index=0&id=623c86c9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "623c86c9",
  null
  
)

export default component.exports